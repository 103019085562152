<template>
  <LayoutWrapper
    class="flex"
    container-class="grow overflow-hidden"
  >
    <template #header>
      <Header />
    </template>
    <template #contentNoWrapper>
      <slot />
    </template>
    <template #footer>
      <LazyFooter v-if="showFooter" />
    </template>
  </LayoutWrapper>
</template>

<script setup>
const { getLocaleMessages: localeMessages } = useLocaleStore()
const { t } = useI18n()

useHead({
  titleTemplate: `%s - ${t('siteTitleBrand')}`,

  // link: [{
  //   rel: 'icon',
  //   type: 'image/x-icon',
  //   href: () => t('favicon')
  // }],

  script: [{
    innerHTML: () => localeMessages.organizationData,
    type: 'application/ld+json',
  }],

  meta: () => [
    {
      hid: 'author',
      name: 'author',
      content: () => t('siteTitleBrand'),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => t('siteTitleBrand'),
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () => t('siteTitleBrand'),
    },
  ],
})

const route = useRoute()

const PREVENT_FOOTER_ROUTES = ['map']
const showFooter = ref(!PREVENT_FOOTER_ROUTES.includes(route.name))

watch(route, (newRoute) => {
  showFooter.value = !PREVENT_FOOTER_ROUTES.includes(newRoute.name)
})
</script>
